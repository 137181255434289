import { default as _404L1kxcILJHiMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/404.vue?macro=true";
import { default as _91_46_46_46slug_93e4HogdpxX9Meta } from "/codebuild/output/src1906671276/src/gx-booking/pages/[...slug].vue?macro=true";
import { default as _91_91filter_93_93eQqjgWuMiMMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexkCdvMaRBn4Meta } from "/codebuild/output/src1906671276/src/gx-booking/pages/account/index.vue?macro=true";
import { default as notifications7RHqwJVdBgMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/account/notifications.vue?macro=true";
import { default as paymentsZhxKqWBF0SMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/account/payments.vue?macro=true";
import { default as personal_45infoOAKWOwEfisMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/account/personal-info.vue?macro=true";
import { default as securityPqrc7S3CWMMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/account/security.vue?macro=true";
import { default as confirmokayN0mjgUMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexhhL2CHAo4MMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93DEFRSS7wSZMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93z95IXECKWTMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/gratuity/[accessKey].vue?macro=true";
import { default as loginrDPXoD8cY9Meta } from "/codebuild/output/src1906671276/src/gx-booking/pages/login.vue?macro=true";
import { default as reset_45passwordJNmbCUyMV5Meta } from "/codebuild/output/src1906671276/src/gx-booking/pages/reset-password.vue?macro=true";
import { default as set_45passwordE5XZz423WgMeta } from "/codebuild/output/src1906671276/src/gx-booking/pages/set-password.vue?macro=true";
export default [
  {
    name: _404L1kxcILJHiMeta?.name ?? "404",
    path: _404L1kxcILJHiMeta?.path ?? "/404",
    meta: _404L1kxcILJHiMeta || {},
    alias: _404L1kxcILJHiMeta?.alias || [],
    redirect: _404L1kxcILJHiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4HogdpxX9Meta?.name ?? "slug",
    path: _91_46_46_46slug_93e4HogdpxX9Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93e4HogdpxX9Meta || {},
    alias: _91_46_46_46slug_93e4HogdpxX9Meta?.alias || [],
    redirect: _91_46_46_46slug_93e4HogdpxX9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_93eQqjgWuMiMMeta?.name ?? "account-bookings-filter",
    path: _91_91filter_93_93eQqjgWuMiMMeta?.path ?? "/account/bookings/:filter?",
    meta: _91_91filter_93_93eQqjgWuMiMMeta || {},
    alias: _91_91filter_93_93eQqjgWuMiMMeta?.alias || [],
    redirect: _91_91filter_93_93eQqjgWuMiMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/account/bookings/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: indexkCdvMaRBn4Meta?.name ?? "account",
    path: indexkCdvMaRBn4Meta?.path ?? "/account",
    meta: indexkCdvMaRBn4Meta || {},
    alias: indexkCdvMaRBn4Meta?.alias || [],
    redirect: indexkCdvMaRBn4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notifications7RHqwJVdBgMeta?.name ?? "account-notifications",
    path: notifications7RHqwJVdBgMeta?.path ?? "/account/notifications",
    meta: notifications7RHqwJVdBgMeta || {},
    alias: notifications7RHqwJVdBgMeta?.alias || [],
    redirect: notifications7RHqwJVdBgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: paymentsZhxKqWBF0SMeta?.name ?? "account-payments",
    path: paymentsZhxKqWBF0SMeta?.path ?? "/account/payments",
    meta: paymentsZhxKqWBF0SMeta || {},
    alias: paymentsZhxKqWBF0SMeta?.alias || [],
    redirect: paymentsZhxKqWBF0SMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/account/payments.vue").then(m => m.default || m)
  },
  {
    name: personal_45infoOAKWOwEfisMeta?.name ?? "account-personal-info",
    path: personal_45infoOAKWOwEfisMeta?.path ?? "/account/personal-info",
    meta: personal_45infoOAKWOwEfisMeta || {},
    alias: personal_45infoOAKWOwEfisMeta?.alias || [],
    redirect: personal_45infoOAKWOwEfisMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/account/personal-info.vue").then(m => m.default || m)
  },
  {
    name: securityPqrc7S3CWMMeta?.name ?? "account-security",
    path: securityPqrc7S3CWMMeta?.path ?? "/account/security",
    meta: securityPqrc7S3CWMMeta || {},
    alias: securityPqrc7S3CWMMeta?.alias || [],
    redirect: securityPqrc7S3CWMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: confirmokayN0mjgUMeta?.name ?? "experiences-parentId-serviceId-confirm",
    path: confirmokayN0mjgUMeta?.path ?? "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmokayN0mjgUMeta || {},
    alias: confirmokayN0mjgUMeta?.alias || [],
    redirect: confirmokayN0mjgUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexhhL2CHAo4MMeta?.name ?? "experiences-parentId-serviceId",
    path: indexhhL2CHAo4MMeta?.path ?? "/experiences/:parentId()/:serviceId()",
    meta: indexhhL2CHAo4MMeta || {},
    alias: indexhhL2CHAo4MMeta?.alias || [],
    redirect: indexhhL2CHAo4MMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93DEFRSS7wSZMeta?.name ?? "experiences-parentId-serviceId-return-bookingId",
    path: _91bookingId_93DEFRSS7wSZMeta?.path ?? "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93DEFRSS7wSZMeta || {},
    alias: _91bookingId_93DEFRSS7wSZMeta?.alias || [],
    redirect: _91bookingId_93DEFRSS7wSZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91accessKey_93z95IXECKWTMeta?.name ?? "gratuity-accessKey",
    path: _91accessKey_93z95IXECKWTMeta?.path ?? "/gratuity/:accessKey()",
    meta: _91accessKey_93z95IXECKWTMeta || {},
    alias: _91accessKey_93z95IXECKWTMeta?.alias || [],
    redirect: _91accessKey_93z95IXECKWTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/gratuity/[accessKey].vue").then(m => m.default || m)
  },
  {
    name: loginrDPXoD8cY9Meta?.name ?? "login",
    path: loginrDPXoD8cY9Meta?.path ?? "/login",
    meta: loginrDPXoD8cY9Meta || {},
    alias: loginrDPXoD8cY9Meta?.alias || [],
    redirect: loginrDPXoD8cY9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordJNmbCUyMV5Meta?.name ?? "reset-password",
    path: reset_45passwordJNmbCUyMV5Meta?.path ?? "/reset-password",
    meta: reset_45passwordJNmbCUyMV5Meta || {},
    alias: reset_45passwordJNmbCUyMV5Meta?.alias || [],
    redirect: reset_45passwordJNmbCUyMV5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: set_45passwordE5XZz423WgMeta?.name ?? "set-password",
    path: set_45passwordE5XZz423WgMeta?.path ?? "/set-password",
    meta: set_45passwordE5XZz423WgMeta || {},
    alias: set_45passwordE5XZz423WgMeta?.alias || [],
    redirect: set_45passwordE5XZz423WgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1906671276/src/gx-booking/pages/set-password.vue").then(m => m.default || m)
  }
]